import { get } from 'lodash';

import { config } from '@grafana/runtime';
import { registerPluginSubtitleExtension } from 'app/features/plugins/admin/components/PluginSubtitle';
import {
  setSandboxEnabledCheck,
  isPluginFrontendSandboxEnabled as isPluginFrontendSandboxEnabledInOss,
} from 'app/features/plugins/sandbox/sandbox_plugin_loader_registry';

import { FrontendSandboxSwitchWrapper } from './FrontendSandboxSwitch';
import { apiRequest } from './api/baseAPI';
import { SandboxSettingsSpec } from './api/types';

async function shouldLoadPluginInFrontendSandbox(params: { pluginId: string }): Promise<boolean> {
  if (!config.bootData.user.isSignedIn) {
    // If the user is not signed in, fallback to the OSS check
    return isPluginFrontendSandboxEnabledInOss(params);
  }
  const settings = await apiRequest<{ spec: SandboxSettingsSpec }>({
    url: `/sandbox-settings/${params.pluginId}`,
    showErrorAlert: false,
  });
  if ('error' in settings) {
    if (get(settings, 'error.status') === 404) {
      // If the sandbox settings are not found in storage, fallback to the OSS check
      const isEnabledInOss = await isPluginFrontendSandboxEnabledInOss(params);
      return isEnabledInOss;
    }
    console.error('Error fetching sandbox settings', settings.error);
    return false;
  }
  return settings.data.spec.enabled;
}

export function initSandboxPluginLoaderRegistry() {
  setSandboxEnabledCheck(shouldLoadPluginInFrontendSandbox);
  registerPluginSubtitleExtension(FrontendSandboxSwitchWrapper);
}
